/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useGetListingLotSkusQuery, useUploadUnitPriceMutation } from '../../../components/api/BuyerApi'
import { CreateFileSelect } from '../../../components/Form'
import { hideNull } from '../../../components/hideNull'
import { skuToDescription } from '../../../components/skuToDescription'
import Spinner from '../../../components/spinner'
import { NOOP, quickAndDirtyCSV } from '../../../components/utils'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '346', listingLotId = '1260' } = props

  // query
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId, isVendor: false }) // N.B. from this page, we are always the buyer!
  const uploadUnitPriceMutation = useUploadUnitPriceMutation()
  const { isSuccess = false, data: listingLotSkus = {} } = listingLotSkusQuery
  // refs
  const formRef = React.useRef()

  // state
  const [file, setFile] = React.useState(undefined)
  const [benchmarkFileHref, setBenchmarkFileHref] = React.useState('#')

  React.useEffect(() => {
    if (isSuccess) {
      // convert to csv...
      const header = quickAndDirtyCSV([
        'listing_lot_sku_id',
        'Description',
        'Grade',
        'Quantity',
        'unit_price'
      ])
      const body = listingLotSkus.listing_lot.listing_lot_skus.map(sku => {
        return quickAndDirtyCSV([
          sku.listing_lot_sku_id,
          skuToDescription(sku),
          hideNull(sku.grade),
          hideNull(sku.qty),
          hideNull(sku.unit_price)
        ])
      }).join('') + '\n'
      const csv = '\uFEFF' + header + body
      setBenchmarkFileHref('data:text/csv;charset=utf-8,' + encodeURI(csv))
    }
  }, [isSuccess, listingLotSkus, setBenchmarkFileHref])

  // functions
  const onHide = () => {
    clearFile()
    setOpenModal(false)
    uploadUnitPriceMutation.reset()
  }
  const internalOnSave = () => {
    uploadBenchmarkFile()
    clearFile()
  }

  const clearFile = () => {
    setFile(undefined)
    // N.B. the only way to reliably clear the input[type=file]!
    formRef.current.reset()
  }

  const onChangeFile = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  }

  /**
   * Upload the product manifest file.
   */
  const uploadBenchmarkFile = () => {
    if (typeof file !== 'undefined') {
      console.debug('Sending benchmark to backend...')
      uploadUnitPriceMutation.mutate({
        listingId,
        listingLotId,
        formData: {
          'listing_lot[unit_price_csv_file]': file
        }
      })
    }
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Bulk upload</h5>

                <p>Upload your unit prices by filling in the <a
                href={benchmarkFileHref}
                download={`unitprices-listing-${listingId}-lot-${listingLotId}.csv`}
                target="_blank" rel="noreferrer"
                className="font-weight-bold text-capitalize"
                >product manifest</a>.</p>

                {/* choose benchmark file */}
                <form ref={formRef}>
                  <CreateFileSelect
                    label="Upload product manifest file (.CSV format only)"
                    value={file}
                    onChange={e => onChangeFile(e)}
                    />
                </form>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={typeof file === 'undefined'}>
                        {uploadUnitPriceMutation.isLoading ? <Spinner /> : <span>Upload</span>}
                    </Button>
                </div>

                { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>}

                { uploadUnitPriceMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Upload successful!</Alert> }
                { uploadUnitPriceMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating file. ' + (uploadUnitPriceMutation.error.response.data.message || uploadUnitPriceMutation.error.message)}</Alert> }
                { listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error retrieving data. ' + listingLotSkusQuery.error.message}</Alert> }

            </Modal.Body>
        </Modal>

        {process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>}
      </>
}

export default Page
